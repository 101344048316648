import React from "react"
import { SiFacebook } from "react-icons/si"
import {
  FaTwitter,
  FaTelegram,
  FaYoutube,
  FaLinkedin,
  FaInstagramSquare
} from "react-icons/fa"

import footerStyles from "./Footer.module.scss"

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         author
  //       }
  //     }
  //   }
  // `)

  const socialMedias = [
    {
      id: 1,
      name: "Facebook",
      url: "https://www.facebook.com/ethiotelecom/",
      icon: <SiFacebook />,
      color: "#3b5999"
    },
    {
      id: 2,
      name: "Twitter",
      url: "https://twitter.com/ethiotelecom",
      icon: <FaTwitter />,
      color: "#55acee"
    },
    {
      id: 3,
      name: "Telegram",
      url: "https://t.me/ethio_telecom",
      icon: <FaTelegram />,
      color: "#0088cc"
    },
    {
      id: 4,
      name: "Youtube",
      url: "https://www.youtube.com/channel/UCW4ZjqFCCFukY94tZO0O5FA",
      icon: <FaYoutube />,
      color: "#cd201f"
    },
    {
      id: 5,
      name: "Linkedin",
      url: "https://www.linkedin.com/company/ethio-telecom/",
      icon: <FaLinkedin />,
      color: "#0077B5"
    },

    {
      id: 6,
      name: "instagram",
      url: "https://www.instagram.com/ethiotelecom/?hl=en",
      icon: <FaInstagramSquare />,
      color: "#e4405f"
    }
  ]

  return (
    <footer className={footerStyles.footer}>
      <div>
        &copy; Copyright 2020 to {new Date().getFullYear()}
        <a
          href="https://www.ethiotelecom.et"
          target="_blank"
          rel="noreferrer noopener"
        >
          {" "}
          Ethio telecom
        </a>
        . All Right Reserved.
        {/* {data.site.siteMetadata.author}. */}
      </div>

      <div>
        <ul>
          {socialMedias &&
            socialMedias.map(({ url, id, name, icon, color }) => {
              return (
                <li key={id}>
                  <a
                    href={url}
                    title={name}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color }}
                  >
                    {icon}
                  </a>
                </li>
              )
            })}
        </ul>
      </div>
    </footer>
  )
}

export default Footer
