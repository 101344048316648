import React from "react"
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import { AiOutlineContacts, AiOutlineAppstore } from "react-icons/ai"
// import { FcAbout } from "react-icons/fc"
import { FaRegBuilding } from "react-icons/fa"

import logo from "../images/ethio-App-store-log.svg"
import "bootstrap/dist/css/bootstrap.min.css"
import headerStyles from "./Header.module.scss"

const Header = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  // console.log(data)

  return (
    <>
      {/* <div className={`${headerStyles.topBarFlexContainer} `}>
        <div className={headerStyles.topBarFlexItem}>
          <img src={logo} alt="logo" width="250px"></img>
        </div>
      </div> */}
      <header className={`${headerStyles.header}  sticky-top`}>
        <nav className="shadow-sm bg-white rounded">
          <ul className={headerStyles.navList}>
            <li>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.activeNavItem}
                // onClick="window.location.href=this"
                exact
                to="/"
              >
                <img src={logo} alt="logo" width="200px"></img>
              </Link>
            </li>
            <li>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.activeNavItem}
                to="/apps"
                //Added since the link click event didn't load the page properly specially image size is not proper
                // onClick="window.location.href=this"
                // to="/"
              >
                <AiOutlineAppstore size={22} style={{ color: "#8DC63F" }} />{" "}
                Apps
              </Link>
            </li>
            <li>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.activeNavItem}
                to="/about"
              >
                {/* <FcAbout size={22} style={{ color: "#2d2d2d" }} /> */}
                <FaRegBuilding size={22} style={{ color: "#8DC63F" }} />
                { " "}About
              </Link>
            </li>
            <li>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.activeNavItem}
                to="/contact"
              >
                <AiOutlineContacts size={24} style={{ color: "#8DC63F" }} />{" "}
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Header
